import React from 'react';
import { Routes, Route, HashRouter } from "react-router-dom";
import VisitorForm from './components/VisitorForm';
import VisitorList from './components/VisitorList';
import UpdateForm from './components/UpdateForm';
import Login from "./Login";

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" exact element={<Login />}></Route>
        <Route path="/add" exact element={<VisitorForm />}></Route>
        <Route path="/visitors" exact element={<VisitorList />}></Route>
        <Route path="/visitors/:id" exact element={<UpdateForm />}></Route>
      </Routes>
    </HashRouter>
  );
}

export default App;
