import { useState, useRef, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { useReactToPrint } from "react-to-print";
import { TableContainer, Table, TableBody, TableRow, TableCell } from '@mui/material';
import QRCode from 'qrcode.react';


function PrintForm({ onClick, visitorData }) {
    const [showform, setShowform] = useState(false);
    const componentRef = useRef();
    const [name, setName] = useState("");
    const [cname, setCname] = useState("");
    const [caddress, setCaddress] = useState("");
    const [mobile, setMobile] = useState("");
    const [meet, setMeet] = useState("");
    const [purpose, setPurpose] = useState("");
    const [department, setDepartmentt] = useState("");
    const [whatsapp, setWhatsapp] = useState("");
    const [path, setPath] = useState("");
    const [image, setImage] = useState("");
    const [response, setReponse] = useState("");
    const [responseReason, setReponseReason] = useState("");
    const [intime, setIntime] = useState("");
    const [outtime, setOuttime] = useState("");
    const [qdata, setQdata] = useState("");

    useEffect(() => {
        setShowform(true);
        setName(visitorData.visitor_name);
        setCname(visitorData.company_name);
        setCaddress(visitorData.company_address);
        setMobile(visitorData.visitor_mobile);
        setMeet(visitorData.to_meet_whom);
        setPurpose(visitorData.purpose);
        setDepartmentt(visitorData.department);
        setWhatsapp(visitorData.whatsapp_no);
        setPath(visitorData.image);
        setReponse(visitorData.response);
        setReponseReason(visitorData.response_reason);
        setIntime(checkDate(visitorData.created_at));
        setOuttime(visitorData.details);
        let path = 'https://deeps.blog/img/post/' + visitorData.photo_path;
        setImage(path);
        gotowhatsapp();
    }, []);


    function checkDate(data) {
        const date = new Date(data);

        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            // timeZoneName: 'short'
        };

        const humanReadableDate = date.toLocaleString('en-US', options);
        return humanReadableDate;
    }


    function gotowhatsapp() {
        var visitor_name = visitorData.visitor_name;
        var company_name = visitorData.company_name;
        var company_address = visitorData.company_address;
        var visitor_mobile = visitorData.visitor_mobile;
        var to_meet_whom = visitorData.to_meet_whom;
        var purpose = visitorData.purpose;
        var department = visitorData.department;
        var whatsapp_no = visitorData.whatsapp_no;
        var resUrl = "https://visitors.deeps.blog/%23/visitors/" + visitorData.id;
        var response = visitorData.response;
        var response_reason = visitorData.response_reason;
        var in_time = visitorData.created_at;
        var out_time = visitorData.details;

        var url =
            "WhatsApp Number: " +
            whatsapp_no +
            "\n" +
            "Response: " +
            response +
            "\n" +
            "Response Reason: " +
            response_reason +
            "\n" +
            "In Time: " +
            in_time +
            "\n" +
            "Out Time: " +
            out_time +
            "\n" +
            "Visitor Name: " +
            visitor_name +
            "\n" +
            "Company Id: " +
            company_name +
            "\n" +
            "Company Address: " +
            company_address +
            "\n" +
            "Visitor Mobile: " +
            visitor_mobile +
            "\n" +
            "To Meet Whom: " +
            to_meet_whom +
            "\n" +
            "Purpose: " +
            purpose +
            "\n" +
            "Department: " +
            department +
            "\n" +
            "Response Link: " +
            resUrl;


        setQdata(url);

    }

    const printRecord = () => {
        console.log("Print record..");
        handlePrint();
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            {showform && (
                <>
                    <div>
                        <IconButton
                            style={{ position: "absolute", top: "0", left: "10" }}
                            onClick={printRecord}
                        >
                            <LocalPrintshopIcon
                                style={{ fontSize: "30px", color: "darkgreen" }}
                                className="cursor-pointer"
                            />
                        </IconButton>
                        <IconButton
                            style={{ position: "absolute", top: "0", right: "0" }}
                            onClick={onClick}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Box sx={{ ml: 3 }} />
                        <div ref={componentRef} style={{ marginLeft: "20px", marginRight: "20px", marginTop: "20px" }}>
                            <span>
                                <div className="flex items-center justify-center mb-2">
                                    {/* Logo */}
                                    <img
                                        src="/logo.png"
                                        alt="Logo"
                                        className="h-16"
                                    />
                                    <div>
                                        <h4 className="register-heading ml-4 text-lg">W.C.L., C.W.S., TADALI </h4>
                                        <h1 className="register-heading ml-4 text-lg">VISITOR GATE PASS </h1>
                                    </div>
                                </div>
                                <Box sx={{ ml: 1 }} />
                                <div className="flex items-center justify-center">
                                    <div>
                                        <TableContainer>
                                            <Table aria-label="simple table">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell align="left" size="small">
                                                            <Typography variant="subtitle2">
                                                                {/* {Object.keys(visitorData).map((key) => (
                                                                    <li key={key}>
                                                                        <strong>{key.replace('_', ' ')}:</strong> {visitorData[key]}
                                                                    </li>
                                                                ))} */}
                                                            </Typography>
                                                            <Typography variant="subtitle2">
                                                                <strong>Visitor Name:</strong> {name}
                                                            </Typography>
                                                            <Typography variant="subtitle2">
                                                                <strong>Company Name:</strong> {cname}
                                                            </Typography>
                                                            <Typography variant="subtitle2">
                                                                <strong>Company Address:</strong> {caddress}
                                                            </Typography>
                                                            <Typography variant="subtitle2">
                                                                <strong>Visitor Mobile:</strong> {mobile}
                                                            </Typography>
                                                            <Typography variant="subtitle2">
                                                                <strong>To Whom Meet:</strong> {meet}
                                                            </Typography>
                                                            <Typography variant="subtitle2">
                                                                <strong>Purpose:</strong> {purpose}
                                                            </Typography>
                                                            <Typography variant="subtitle2">
                                                                <strong>Department:</strong> {department}
                                                            </Typography>
                                                            <Typography variant="subtitle2">
                                                                <strong>WhatsApp:</strong> {whatsapp}
                                                            </Typography>
                                                            <Typography variant="subtitle2">
                                                                <strong>Response:</strong> {response}
                                                            </Typography>
                                                            <Typography variant="subtitle2">
                                                                <strong>Response Reason:</strong> {responseReason}
                                                            </Typography>
                                                            <Typography variant="subtitle2">
                                                                <strong>In Time:</strong> {intime}
                                                            </Typography>
                                                            <Typography variant="subtitle2">
                                                                <strong>Out Time:</strong> {outtime}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                    <div className="image-upload-container mb-10" style={{ maxWidth: "20%" }}>
                                        <div className="box-decoration">
                                            <label htmlFor="image-upload-input" className="image-upload-label">
                                            </label>
                                            <div style={{ cursor: "pointer" }}>
                                                {image ? (
                                                    <img src={image} alt="upload image" className="img-display-after" />
                                                ) : (
                                                    <img src="./photo.png" alt="upload image" className="img-display-before" />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ml-32 mt-12">
                                        {/* <QRCode value={JSON.stringify(qdata)} /> */}
                                        <QRCode value={qdata} />
                                    </div>
                                </div>
                                <div className="flex justify-between mx-10 mt-10">
                                    <h2>Department Head Sign</h2>
                                    <h2>Security Gaurd Sign </h2>
                                    <h2>Visitor Sign</h2>
                                </div>
                                <Box sx={{ m: 1 }} />
                            </span>
                        </div>
                        <div className="flex justify-end">
                            <div
                                onClick={printRecord}
                            >
                                <div className="bg-transparent cursor-pointer hover:bg-green-500 text-green-700 font-semibold hover:text-white py-2 px-4 border border-green-500 hover:border-transparent rounded">
                                    Print
                                </div>
                            </div>
                            <div
                                onClick={onClick}
                                className="ml-5"
                            >
                                <div className="bg-transparent cursor-pointer hover:bg-orange-500 text-orange-700 font-semibold hover:text-white py-2 px-4 border border-orange-500 hover:border-transparent rounded">
                                    Close
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default PrintForm;
