import React, { useState, useEffect } from 'react';
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import EditIcon from '@mui/icons-material/Edit';
import { CSVLink, CSVDownload } from "react-csv";

function Button(props) {
  return (
    <button
      className={`px-4 py-2 ${props.color} ${props.size} rounded-md`}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}

export default function DataTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [visitorData, setVisitorData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const baseURL = process.env.REACT_APP_API_URL;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const csvHeaders = [
    { label: 'Name' },
    { label: 'Email' },
  ];

  function checkDate(data) {
    const date = new Date(data);

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      // timeZoneName: 'short'
    };

    const humanReadableDate = date.toLocaleString('en-US', options);
    return humanReadableDate;
  }


  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(baseURL + 'visitors');
      setVisitorData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const filteredVisitors = visitorData.filter((visitor) =>
    visitor.visitor_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    visitor.company_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    visitor.company_address.toLowerCase().includes(searchQuery.toLowerCase()) ||
    visitor.visitor_mobile.toLowerCase().includes(searchQuery.toLowerCase()) ||
    visitor.to_meet_whom.toLowerCase().includes(searchQuery.toLowerCase()) ||
    visitor.purpose.toLowerCase().includes(searchQuery.toLowerCase()) ||
    visitor.department.toLowerCase().includes(searchQuery.toLowerCase()) ||
    visitor.whatsapp_no.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const editRecord = (event) => {
    var resUrl = "https://visitors.deeps.blog/#/visitors/" + event;
    const newTab = window.open(resUrl, '_blank');
    newTab.focus();
  };

  const deleteRecord = (event) => {
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to deletethis record?.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteApi(event)
        },
        {
          label: 'No',
        }
      ]
    });
  };

  const deleteApi = async (event) => {
    // starting
    await axios
      .delete(baseURL + "visitors/" + event, {})
      .then(function (response) {
        // Swal.fire("Deleted!", "Your file has been deleted.", "success");
        fetchData();
      })
      .catch(function (error) {
        // Swal.fire("Error!", "Something went wrong.", "error");
        console.log("kcheckpost" + error); //return 429
      });
    // ending
  };

  return (
    <>
      <div className="mx-32 my-10">
        <div className="flex items-center justify-center mb-2">
          {/* Logo */}
          <img
            src="/logo.png"
            alt="Logo"
            className="h-16"
          />
          <div>
            <h4 className="register-heading ml-4 text-lg">W.C.L., C.W.S., TADALI </h4>
            <h1 className="register-heading ml-4 text-lg">VISITOR GATE PASS </h1>
          </div>
        </div>
        <div className='flex justify-between'>
          <div>
            <input
              type="text"
              placeholder="Search by visitor name"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="mb-2 px-2 py-1 border rounded"
            />
          </div>
          <CSVLink
            data={filteredVisitors}
            // headers={csvHeaders}
            filename={'exported_data.csv'}
            className="btn btn-primary bg-green-500 p-2 m-2 text-white border-10 rounded-md"
          >
            Export CSV
          </CSVLink>
        </div>

        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table" stripe="odd">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="left"
                    style={{ minWidth: "100px", fontWeight: "bold" }}
                  >
                    ID
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ minWidth: "100px", fontWeight: "bold" }}
                  >
                    Visitor Name
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ minWidth: "100px", fontWeight: "bold" }}
                  >
                    Visitor Mobile
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ minWidth: "100px", fontWeight: "bold" }}
                  >
                    Company ID
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ minWidth: "100px", fontWeight: "bold" }}
                  >
                    Company Employee
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ minWidth: "100px", fontWeight: "bold" }}
                  >
                    Company Address
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ minWidth: "100px", fontWeight: "bold" }}
                  >
                    To Meet Whom
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ minWidth: "100px", fontWeight: "bold" }}
                  >
                    Department
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ minWidth: "100px", fontWeight: "bold" }}
                  >
                    Purpose
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ minWidth: "100px", fontWeight: "bold" }}
                  >
                    WhatsApp No
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ minWidth: "100px", fontWeight: "bold" }}
                  >
                    In Time
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ minWidth: "100px", fontWeight: "bold" }}
                  >
                    Out Time
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ minWidth: "100px", fontWeight: "bold" }}
                  >
                    Response
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ minWidth: "100px", fontWeight: "bold" }}
                  >
                    Response Reason
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredVisitors
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        <TableCell align="left">{row.id}</TableCell>
                        <TableCell align="left">{row.visitor_name}</TableCell>
                        <TableCell align="left">{row.visitor_mobile}</TableCell>
                        <TableCell align="left">{row.company_name}</TableCell>
                        <TableCell align="left">{row.company_employee}</TableCell>
                        <TableCell align="left">{row.company_address}</TableCell>
                        <TableCell align="left">{row.to_meet_whom}</TableCell>
                        <TableCell align="left">{row.department}</TableCell>
                        <TableCell align="left">{row.purpose}</TableCell>
                        <TableCell align="left">{row.whatsapp_no}</TableCell>
                        <TableCell align="left">{checkDate(row.created_at)}</TableCell>
                        <TableCell align="left">{row.details}</TableCell>
                        <TableCell align="left">{row.response}</TableCell>
                        <TableCell align="left">{row.response_reason}</TableCell>
                        <TableCell style={{ width: 20 }}>
                          <div className="flex">
                            <Button color="bg-blue-500 text-white" size="text-sm" onClick={() => editRecord(row.id)}>
                              Edit
                            </Button>
                            <Button color="bg-red-500 text-white ml-2" size="text-sm" onClick={() => deleteRecord(row.id)}>
                              Delete
                            </Button>
                          </div>
                          {/* <div className="flex">
                            <EditIcon
                              style={{ fontSize: "20px", color: "darkgreen" }}
                              className="cursor-pointer mx-1"
                              onClick={() => editRecord(row.id)}
                            />
                            <DeleteIcon
                              style={{ fontSize: "20px", color: "darkred" }}
                              className="cursor-pointer mx-1"
                              onClick={() => deleteRecord(row.id)}
                            />
                          </div> */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={filteredVisitors.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </>
  );
}
