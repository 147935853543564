import { useState, useEffect, useRef } from "react";
import axios from 'axios';
import PrintForm from './PrintForm';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const VisitorForm = () => {
  const componentRef = useRef();
  const [editopen, setEditOpen] = useState(false);
  const handleEditOpen = () => setEditOpen(true);
  const handleEditClose = () => setEditOpen(false);
  const [image, setImage] = useState(null);
  const hiddenFileInput = useRef(null);
  const [selectedOptionEmp, setSelectedOptionEmp] = useState('');
  const baseURL = process.env.REACT_APP_API_URL;
  const [formData, setFormData] = useState({
    visitor_name: '',
    company_name: '',
    company_address: '',
    visitor_mobile: '',
    to_meet_whom: '',
    purpose: '',
    department: '',
    whatsapp_no: '',
    photo_path: '',
    image: '',
    response: '',
    response_reason: '',
    company_employee: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleReset = (e) => {
    setFormData({
      visitor_name: '',
      company_name: '',
      company_address: '',
      visitor_mobile: '',
      to_meet_whom: '',
      purpose: '',
      department: '',
      whatsapp_no: '',
      photo_path: '',
      image: '',
      response: '',
      response_reason: '',
      company_employee: '',
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(baseURL + 'visitors', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Important for sending blobs
        },
      });
      console.log('Form data posted successfully:', response.data.created_id);
      gotowhatsapp(response.data.created_id);
      // Reset form data after successful submission
      setFormData({
        visitor_name: '',
        company_name: '',
        company_address: '',
        visitor_mobile: '',
        to_meet_whom: '',
        purpose: '',
        department: '',
        whatsapp_no: '',
        photo_path: '',
        response: '',
        details: '',
        response_reason: '',
        company_employee: '',
      });
    } catch (error) {
      console.error('Error posting form data:', error);
    }
  };

  function gotowhatsapp(id) {
    var visitor_name = formData.visitor_name;
    var company_name = formData.company_name;
    var company_address = formData.company_address;
    var visitor_mobile = formData.visitor_mobile;
    var to_meet_whom = formData.to_meet_whom;
    var purpose = formData.purpose;
    var department = formData.department;
    var whatsapp_no = formData.whatsapp_no;
    var company_employee = formData.company_employee;
    var resUrl = "https://visitors.deeps.blog/%23/visitors/" + id;

    var url =
      "https://wa.me/91" +
      whatsapp_no +
      "?text=" +
      "Visitor Name: " +
      visitor_name +
      "%0a" +
      "Company Employee: " +
      company_employee +
      "%0a" +
      "Company Name: " +
      company_name +
      "%0a" +
      "Company Address: " +
      company_address +
      "%0a" +
      "Visitor Mobile: " +
      visitor_mobile +
      "%0a" +
      "To Meet Whom: " +
      to_meet_whom +
      "%0a" +
      "Purpose: " +
      purpose +
      "%0a" +
      "Department: " +
      department +
      "%0a" +
      "Response Link: " +
      resUrl;

    window.open(url, "_blank").focus();
  }

  function checkDate(data) {
    const date = new Date(data);

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      // timeZoneName: 'short'
    };

    const humanReadableDate = date.toLocaleString('en-US', options);
    return humanReadableDate;
  }


  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const imgname = event.target.files[0].name;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const maxSize = Math.max(img.width, img.height);
        canvas.width = maxSize;
        canvas.height = maxSize;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(
          img,
          (maxSize - img.width) / 2,
          (maxSize - img.height) / 2
        );
        canvas.toBlob(
          (blob) => {
            const file = new File([blob], imgname, {
              type: "image/png",
              lastModified: Date.now(),
            });
            setImage(file);
            setFormData((prevData) => ({
              ...prevData,
              image: file,
            }));
          },
          "image/jpeg",
          0.8
        );
      };
    };
  };


  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleOptionChangeEmp = (event) => {
    setSelectedOptionEmp(event.target.value);
    setFormData((prevData) => ({
      ...prevData,
      company_employee: event.target.value,
    }));
  };


  return (
    <>
      <Modal
        open={editopen}
        onClose={handleEditClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: "auto" }}
      >
        <Box sx={style} ref={componentRef}>
          <PrintForm
            onClick={handleEditClose}
            visitorData={formData}
          />
        </Box>
      </Modal>
      <div className="w-full max-w-md mx-auto my-10">
        <div className="flex items-center justify-center mb-2">
          {/* Logo */}
          <img
            src="/logo.png"
            alt="Logo"
            className="h-16"
          />
          <div>
            <h4 className="register-heading ml-4 text-lg">W.C.L., C.W.S., TADALI </h4>
            <h1 className="register-heading ml-4 text-lg">VISITOR GATE PASS </h1>
          </div>
        </div>
        <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          {/* Form fields */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="visitor_name">
              Visitor Name
            </label>
            <input
              type="text"
              required
              name="visitor_name"
              value={formData.visitor_name}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Visitor Name"
            />
          </div>



          {/* Add more fields */}
          <div className="mb-2">
            <FormControl variant="outlined" sx={{ minWidth: "100%" }}>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="company_name">
                Company Employee
              </label>
              <Select
                value={selectedOptionEmp}
                onChange={handleOptionChangeEmp}
                label="Dropdown"
                sx={{ height: '36px' }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>
          </div>

          {/* Add more fields */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="company_name">
              Company ID
            </label>
            <input
              type="text"
              name="company_name"
              required
              value={formData.company_name}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Company ID"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="company_name">
              Company Address
            </label>
            <input
              type="text"
              required
              name="company_address"
              value={formData.company_address}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Company Address"
            />
          </div>

          {/* Add more fields */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="company_name">
              Visitor Mobile
            </label>
            <input
              type="text"
              required
              name="visitor_mobile"
              value={formData.visitor_mobile}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Visitor Mobile"
            />
          </div>

          {/* Add more fields */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="company_name">
              To Meet Whom
            </label>
            <input
              type="text"
              required
              name="to_meet_whom"
              value={formData.to_meet_whom}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="To Meet Whom"
            />
          </div>

          {/* Add more fields */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="purpose">
              Purpose
            </label>
            <input
              type="text"
              required
              name="purpose"
              value={formData.purpose}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Purpose"
            />
          </div>

          {/* Add more fields */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="department">
              Department
            </label>
            <input
              type="text"
              required
              name="department"
              value={formData.department}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Department"
            />
          </div>

          {/* Add more fields */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="whatsapp_no">
              WhatsApp Number
            </label>
            <input
              type="text"
              required
              name="whatsapp_no"
              value={formData.whatsapp_no}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="WhatsApp Number"
            />
          </div>

          {/* Add more fields */}
          {/* <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="photo_path">
              Photo Path
            </label>
            <input
              type="text"
              name="photo_path"
              value={formData.photo_path}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Photo Path"
            />
          </div> */}

          {/* Add more fields */}
          {/* <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="response">
              Response
            </label>
            <input
              type="text"
              name="response"
              value={formData.response}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Response"
            />
          </div> */}

          {/* Add more fields */}
          {/* <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="response_reason">
              Response Reason
            </label>
            <input
              type="text"
              name="response_reason"
              value={formData.response_reason}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Response Reason"
            />
          </div> */}

          <div className="image-upload-container mb-10" style={{ maxWidth: "50%" }}>
            <div className="box-decoration">
              <label htmlFor="image-upload-input" className="image-upload-label">
                {image ? image.name : "Choose an image"}
              </label>
              <div onClick={handleClick} style={{ cursor: "pointer" }}>
                {image ? (
                  <img src={URL.createObjectURL(image)} alt="upload image" className="img-display-after" />
                ) : (
                  <img src="./photo.png" alt="upload image" className="img-display-before" />
                )}

                <input
                  id="image-upload-input"
                  type="file"
                  name="image"
                  onChange={handleImageChange}
                  ref={hiddenFileInput}
                  style={{ display: "none" }}
                />
              </div>
            </div>
          </div>
          {/* Add other fields similarly */}

          {/* Submit button */}
          <div className="flex items-center justify-center">
            <div
              onClick={handleEditOpen}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-2"
            >
              Print
            </div>
            <div
              onClick={handleReset}
              className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-2"
            >
              Reset
            </div>
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-2"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default VisitorForm;
