import { useState, useEffect, useRef, forwardRef } from "react";
import axios from 'axios';
import PrintForm from './PrintForm';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useParams } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const UpdateForm = () => {
  const componentRef = useRef();
  const [editopen, setEditOpen] = useState(false);
  const handleEditOpen = () => setEditOpen(true);
  const handleEditClose = () => setEditOpen(false);
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedOptionEmp, setSelectedOptionEmp] = useState('');
  const baseURL = process.env.REACT_APP_API_URL;
  const [image, setImage] = useState(null);
  const hiddenFileInput = useRef(null);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const [formData, setFormData] = useState({
    visitor_name: '',
    company_name: '',
    company_employee: '',
    company_address: '',
    visitor_mobile: '',
    to_meet_whom: '',
    purpose: '',
    department: '',
    whatsapp_no: '',
    photo_path: '',
    response: '',
    response_reason: '',
  });
  const { id } = useParams();

  useEffect(() => {
    console.log('Params ', formData.photo_path);
    let path = 'https://deeps.blog/img/post/' + formData.photo_path;
    setImage(path);
    console.log('Path ', path);
  }, [formData])


  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(baseURL + 'visitors/' + id);
      setFormData(response.data[0]);
      setSelectedOption(response.data[0]['response']);
      setSelectedOptionEmp(response.data[0]['company_employee']);


    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleReset = (e) => {
    setFormData({
      visitor_name: '',
      company_name: '',
      company_employee: '',
      company_address: '',
      visitor_mobile: '',
      to_meet_whom: '',
      purpose: '',
      department: '',
      whatsapp_no: '',
      photo_path: '',
      response: '',
      response_reason: '',
    });
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   // Handle form submission here, e.g., send data to a server or perform validation.
  //   console.log(formData);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(baseURL + 'visitors/' + id, formData);
      setOpen(true);
      console.log('Form data posted successfully:', response.data);
      gotowhatsapp(id);
      // Reset form data after successful submission
      // setFormData({
      //   visitor_name: '',
      //   company_name: '',
      //   company_employee: '',
      //   company_address: '',
      //   visitor_mobile: '',
      //   to_meet_whom: '',
      //   purpose: '',
      //   department: '',
      //   whatsapp_no: '',
      //   photo_path: '',
      //   response: '',
      //   response_reason: '',
      // });
    } catch (error) {
      console.error('Error posting form data:', error);
    }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    if (event.target.value == "Yes") {
      setFormData((prevData) => ({
        ...prevData,
        response_reason: "Allowed",
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        response_reason: "Busy in Meeting",
      }));
    }
    setFormData((prevData) => ({
      ...prevData,
      response: event.target.value,
    }));
  };

  const handleOptionChangeEmp = (event) => {
    setSelectedOptionEmp(event.target.value);
    setFormData((prevData) => ({
      ...prevData,
      company_employee: event.target.value,
    }));
  };

  function gotowhatsapp(id) {
    var visitor_name = formData.visitor_name;
    var company_name = formData.company_name;
    var company_employee = formData.company_employee;
    var company_address = formData.company_address;
    var visitor_mobile = formData.visitor_mobile;
    var to_meet_whom = formData.to_meet_whom;
    var purpose = formData.purpose;
    var department = formData.department;
    var whatsapp_no = formData.whatsapp_no;
    var resUrl = "https://visitors.deeps.blog/%23/visitors/" + id;
    var response = formData.response;
    var response_reason = formData.response_reason;
    var in_time = formData.created_at;
    var out_time = formData.details;

    var url =
      "https://wa.me/91" +
      whatsapp_no +
      "?text=" +
      "Response: " +
      response +
      "%0a" +
      "Response Reason: " +
      response_reason +
      "%0a" +
      "In Time: " +
      in_time +
      "%0a" +
      "Out Time: " +
      out_time +
      "%0a" +
      "Visitor Name: " +
      visitor_name +
      "%0a" +
      "Company Name: " +
      company_name +
      "%0a" +
      "Company Employee: " +
      company_employee +
      "%0a" +
      "Company Address: " +
      company_address +
      "%0a" +
      "Visitor Mobile: " +
      visitor_mobile +
      "%0a" +
      "To Meet Whom: " +
      to_meet_whom +
      "%0a" +
      "Purpose: " +
      purpose +
      "%0a" +
      "Department: " +
      department +
      "%0a" +
      "Response Link: " +
      resUrl;

    window.open(url, "_blank").focus();
  }

  return (
    <>
      {/* <Alert severity="success">Success!</Alert> */}
      {/* <Alert severity="error">Failed!</Alert> */}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Success!
        </Alert>
      </Snackbar>
      <Modal
        open={editopen}
        onClose={handleEditClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: "auto" }}
      >
        <Box sx={style} ref={componentRef}>
          <PrintForm
            onClick={handleEditClose}
            visitorData={formData}
          />
        </Box>
      </Modal>
      <div className="w-full max-w-md mx-auto my-10">
        <div className="flex items-center justify-center mb-2">
          {/* Logo */}
          <img
            src="/logo.png"
            alt="Logo"
            className="h-16"
          />
          <div>
            <h4 className="register-heading ml-4 text-lg">W.C.L., C.W.S., TADALI </h4>
            <h1 className="register-heading ml-4 text-lg">VISITOR GATE PASS </h1>
          </div>
        </div>
        <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">

          <div>
            <FormControl variant="outlined" sx={{ minWidth: "100%" }}>
              <Typography variant="subtitle1" className="block text-gray-700 text-sm font-bold mb-2" gutterBottom>
                Response
              </Typography>
              <Select
                value={selectedOption}
                onChange={handleOptionChange}
                label="Dropdown"
                sx={{ height: '36px' }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>
          </div>

          {/* Add more fields */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="response_reason">
              Response Reason
            </label>
            <input
              type="text"
              name="response_reason"
              value={formData.response_reason}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Response Reason"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="response_reason">
              Out Time
            </label>
            <input
              type="time"
              name="details"
              value={formData.details}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Response Reason"
            />
          </div>
          {/* Form fields */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="visitor_name">
              Visitor Name
            </label>
            <input
              type="text"
              name="visitor_name"
              disabled
              value={formData.visitor_name}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Visitor Name"
            />
          </div>

          {/* Add more fields */}


          <div className="mb-2">
            <FormControl variant="outlined" sx={{ minWidth: "100%" }}>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="company_name">
                Company Employee
              </label>
              <Select
                value={selectedOptionEmp}
                onChange={handleOptionChangeEmp}
                label="Dropdown"
                sx={{ height: '36px' }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="company_employee">
              Company ID
            </label>
            <input
              type="text"
              name="company_employee"
              disabled
              value={formData.company_employee}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Company ID"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="company_name">
              Company Name
            </label>
            <input
              type="text"
              name="company_name"
              disabled
              value={formData.company_name}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Company Name"
            />
          </div>

          {/* Add more fields */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="company_name">
              Company Address
            </label>
            <input
              type="text"
              name="company_address"
              disabled
              value={formData.company_address}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Company Address"
            />
          </div>

          {/* Add more fields */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="company_name">
              Visitor Mobile
            </label>
            <input
              type="text"
              name="visitor_mobile"
              disabled
              value={formData.visitor_mobile}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Visitor Mobile"
            />
          </div>

          {/* Add more fields */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="company_name">
              To Meet Whom
            </label>
            <input
              type="text"
              name="to_meet_whom"
              disabled
              value={formData.to_meet_whom}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="To Meet Whom"
            />
          </div>

          {/* Add more fields */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="purpose">
              Purpose
            </label>
            <input
              type="text"
              name="purpose"
              disabled
              value={formData.purpose}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Purpose"
            />
          </div>

          {/* Add more fields */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="department">
              Department
            </label>
            <input
              type="text"
              name="department"
              disabled
              value={formData.department}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Department"
            />
          </div>

          {/* Add more fields */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="whatsapp_no">
              WhatsApp Number
            </label>
            <input
              type="text"
              name="whatsapp_no"
              disabled
              value={formData.whatsapp_no}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="WhatsApp Number"
            />
          </div>

          {/* Add more fields */}
          {/* <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="photo_path">
              Photo Path
            </label>
            <input
              type="text"
              name="photo_path"
              value={formData.photo_path}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Photo Path"
            />
          </div> */}

          {/* Add other fields similarly */}
          <div className="image-upload-container mb-10" style={{ maxWidth: "50%" }}>
            <div className="box-decoration">
              <label htmlFor="image-upload-input" className="image-upload-label">
              </label>
              <div style={{ cursor: "pointer" }}>
                {image ? (
                  <img src={image} alt="upload image" className="img-display-after" />
                ) : (
                  <img src="./photo.png" alt="upload image" className="img-display-before" />
                )}

                <input
                  id="image-upload-input"
                  type="file"
                  // onChange={handleImageChange}
                  ref={hiddenFileInput}
                  style={{ display: "none" }}
                />
              </div>
            </div>
          </div>
          {/* Submit button */}
          <div className="flex items-center justify-center">
            <div
              onClick={handleEditOpen}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-2"
            >
              Print
            </div>
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-2"
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default UpdateForm;
